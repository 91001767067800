<template>
  <div class="row">
    <div class="col-12 col-lg-6 c">
      <div class="card card-body">
        <div class="col-12 text-center">
          <div class="col-12 text-center" v-if="loading">
            <img :src="require('@/assets/images/loading.svg')" alt="" />
          </div>
          <div
            class="alert bg-success g text-white"
            v-if="count == 0 && count != -1"
          >
            <h4 class="text-white">لا يوجد اي ارقام مكررة في حسابك.</h4>
          </div>
          <div class="alert bg-light-danger g" v-if="count > 0 && count != -1">
            <h4>الارقام المكررة في حسابك: {{ count }}</h4>
          </div>
          <div class="col-12 text-center g" v-if="count > 0 && count != -1">
            <button class="btn btn-success" @click="deleteC()">
              حذف الارقام المكررة
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BOverlay,
  BModal,
  VBModal,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BOverlay,
    BModal,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  data() {
    return {
      loading: false,
      current: {},
      count: -1,
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  created() {
    var g = this;
    g.loading = true;
    $.post(api + "/user/contacts/reapeated", {
      jwt: this.user.jwt,
    })
      .then(function (r) {
        g.count = r.response;
        g.loading = false;
      })
      .catch(function () {
        g.loading = false;
        g.$toast({
          component: ToastificationContent,
          props: {
            title: "حدثت مشكلة في الاتصال",
            icon: "EditIcon",
            variant: "danger",
          },
        });
      });
  },
  methods: {
    deleteC() {
      var g = this;
      g.loading = true;
      $.post(api + "/user/contacts/reapeated-delete", {
        jwt: this.user.jwt,
      })
        .then(function (r) {
          g.count = r.response;
          g.loading = false;
          location.reload();
        })
        .catch(function () {
          g.loading = false;
          g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدثت مشكلة في الاتصال",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        });
    },
  },
};
</script>

<style>
.select-way-to-add:hover {
  background: #eee !important;
  cursor: pointer;
}
</style>